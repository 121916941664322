html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
p {
  font-size: 1.2em;
}
#root {
  overflow-x: hidden;
}
